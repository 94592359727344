<template>
  <div>
    <!-- Phones -->
    <base-material-card
      icon="mdi-phone"
      color="info"
      title="Phones"
    >
      <v-form
        ref="phoneForm"
        lazy-validation
        @submit.prevent="updatePhones"
      >
        <v-row
          v-for="phone in person.phones"
          :key="phone.id"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="phone.phone_type_id"
              label="Phone Type"
              :items="mixinItems.phoneTypes"
              :loading="loadingMixins.phoneTypes"
              item-value="id"
              item-text="name"
              :prepend-icon="
                mixinItems.phoneTypes.find(phoneType => phoneType.id === phone.phone_type_id)
                  ? mixinItems.phoneTypes.find(phoneType => phoneType.id === phone.phone_type_id).icon
                  : 'mdi-phone'
              "
              :rules="[rules.required]"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="phone.number"
              label="Number"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              small
              class="success"
              @click="person.phones.push({})"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              Add
            </v-btn>
          </v-col>
        </v-row>

        <v-btn
          fab
          absolute
          bottom
          right
          small
          type="submit"
          :loading="updatingPhones"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-form>
    </base-material-card>

    <!-- Emails -->
    <base-material-card
      icon="mdi-email"
      color="primary"
      title="Emails"
    >
      <v-form
        ref="emailForm"
        lazy-validation
        @submit.prevent="updateEmails"
      >
        <v-row
          v-for="email in person.emails"
          :key="email.id"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="email.email_type_id"
              label="Email Type"
              :items="mixinItems.emailTypes"
              :loading="loadingMixins.emailTypes"
              item-value="id"
              item-text="name"
              :prepend-icon="
                mixinItems.emailTypes.find(emailType => emailType.id === email.email_type_id)
                  ? mixinItems.emailTypes.find(emailType => emailType.id === email.email_type_id).icon
                  : 'mdi-email'
              "
              :rules="[rules.required]"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="email.email"
              label="Email"
              type="email"
              :rules="[rules.required, rules.email]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              small
              color="success"
              @click="person.emails.push({})"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              Add
            </v-btn>
          </v-col>
        </v-row>

        <v-btn
          fab
          absolute
          bottom
          right
          small
          type="submit"
          :loading="updatingEmails"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-form>
    </base-material-card>

    <!-- Socials -->
    <base-material-card
      icon="mdi-web"
      color="secondary"
      title="Web and Social"
    >
      <v-form
        ref="socialForm"
        lazy-validation
        @submit.prevent="updateSocials"
      >
        <v-row
          v-for="social in person.socials"
          :key="social.id"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="social.social_type_id"
              label="Social Type"
              :items="mixinItems.socialTypes"
              :loading="loadingMixins.socialTypes"
              item-value="id"
              item-text="name"
              :prepend-icon="
                mixinItems.socialTypes.find(socialType => socialType.id === social.social_type_id)
                  ? mixinItems.socialTypes.find(socialType => socialType.id === social.social_type_id).icon
                  : 'mdi-link'
              "
              :rules="[rules.required]"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="social.url"
              label="Url"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              small
              color="success"
              @click="person.socials.push({})"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              Add
            </v-btn>
          </v-col>
        </v-row>

        <v-btn
          fab
          absolute
          bottom
          right
          small
          type="submit"
          :loading="updatingSocials"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-form>
    </base-material-card>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.phoneTypes,
        MIXINS.emailTypes,
        MIXINS.socialTypes,
      ]),
    ],

    props: {
      fromAgent: {
        default: false,
        type: Boolean,
      },
    },

    data: () => ({
      rules: {
        required: value => !!value || 'This field is required.',
        email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      },
      updatingPhones: false,
      updatingEmails: false,
      updatingSocials: false,
    }),

    computed: {
      ...mapState({
        customer: state => state.items.customer,
        agent: state => state.items.agent,
      }),

      person () {
        return this.fromAgent ? this.agent : this.customer
      },
    },

    methods: {
      async updatePhones () {
        if (this.$refs.phoneForm.validate()) {
          this.updatingPhones = true
          await new Api().post({
            url: 'customer/phone',
            request: {
              phones: this.person.phones,
              id: this.person.id,
              user: this.person.user,
            },
          })
          this.updatingPhones = false
        }
      },

      async updateEmails () {
        if (this.$refs.emailForm.validate()) {
          this.updatingEmails = true
          await new Api().post({
            url: 'customer/email',
            request: {
              emails: this.person.emails,
              id: this.person.id,
              user: this.person.user,
            },
          })
          this.updatingEmails = false
        }
      },

      async updateSocials () {
        if (this.$refs.socialForm.validate()) {
          this.updatingSocials = true
          await new Api().post({
            url: 'customer/social',
            request: {
              socials: this.person.socials,
              id: this.person.id,
              user: this.person.user,
            },
          })
          this.updatingSocials = false
        }
      },
    },
  }
</script>
